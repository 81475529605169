import { Link } from "react-router-dom";
import { Roles } from "../constants/CommonEnum";
import { OrganizationIcon } from "../assets/icons/OrganizationIcon";
import { ConversionFactorIcon } from "../assets/icons/ConversionFactorIcon";
import { Spin } from "antd";
import {
  ControlOutlined,
  FileOutlined,
  PauseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const BaseNavbar = [
  {
    key: "report",
    icon: <FileOutlined style={{ color: "#264491", fontSize: "18px" }} />,
    label: "report",
    children: [
      {
        key: "EINF_SCREEN",
        roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
        label: <Spin />,
      },
    ],
  },
  {
    key: "DM_REPORT_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: (
      <PauseCircleOutlined style={{ color: "#264491", fontSize: "18px" }} />
    ),
    label: <Link to={`/dm`}>dmReport</Link>,
  },
  {
    key: "MY_DM_REPORT_SCREEN",
    roles: [Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION],
    icon: (
      <PauseCircleOutlined style={{ color: "#264491", fontSize: "18px" }} />
    ),
    label: <Link to={`/my-dm`}>dmReport</Link>,
  },
  {
    key: "ORGANIZATION_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <OrganizationIcon color="#264491" style={{ width: "18px" }} />,
    label: <Link to={`/organization`}>organization</Link>,
  },
  {
    key: "MY_ORGANIZATION_SCREEN",
    roles: [Roles.ADMIN_ORGANIZATION],
    icon: <OrganizationIcon color="#264491" style={{ width: "18px" }} />,
    label: <Link to={`/my-organization`}>myOrganization</Link>,
  },
  {
    key: "CONVERSION_FACTOR_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <ConversionFactorIcon color="#264491" style={{ width: "18px" }} />,
    label: <Link to={`/conversion-factor`}>conversionFactor</Link>,
  },
  {
    key: "TEMPLATE_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <ControlOutlined style={{ color: "#264491", fontSize: "18px" }} />,
    label: <Link to={`/template`}>template</Link>,
  },
  {
    key: "USER_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <UserOutlined style={{ color: "#264491", fontSize: "18px" }} />,
    label: <Link to={`/user`}>user</Link>,
  },
  {
    key: "MY_USER_SCREEN",
    roles: [Roles.ADMIN_ORGANIZATION],
    icon: <UserOutlined style={{ color: "#264491", fontSize: "18px" }} />,
    label: <Link to={`/my-user`}>user</Link>,
  },
];
