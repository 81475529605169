import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Result, Row, Spin } from "antd";


export const InitializingApp = () => {
  const { t } = useTranslation("common");
  return (
    <Row
      justify="center"
      align="middle"
      style={{ width: "100vw", height: "100vh", margin: 0 }}>
      <Col span={24} align="center" justify="center">
        <Result icon={<Spin />} title={t("common.loading")} />
      </Col>
    </Row>
  );
};
