import React from "react";
import { Row, Col, Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Row justify="center" >
      <Col span={24} align="center">
        <Result
          status="404"
          title="Invalid URL"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
};
