export const Roles = {
  SUPERADMIN: "superadmin",
  ADMIN_CROWE: "admin_crowe",
  ADMIN_ORGANIZATION: "admin_organization",
  USER_ORGANIZATION: "user_organization",
};

export const ConsumptionFactorConversion = {
  COMBUSTION_FIXED: "combustion_fixed",
  COMBUSTION_MOBILE: "combustion_mobile",
  ELECTRICITY: "electricity",
  REFRIGERANT: "refrigerant",
};

export const ProfessionalCategoryType = {
  GENERAL: "general",
  BY_COUNTRY: "country",
  BY_BUSINESS_COMPANY: "company",
};

export const QuestionType = {
  MULTIPLE_CHOICE: "multiple_choice",
  MULTIPLE_INPUT_NUMBER: "multiple_input_number",
  ENTITY_SELECT: "entity_select",
  NARRATIVE: "narrative",
  YES_NO: "yes_no",
  CHECKLIST: "checklist",
  NUMBER_BOARD_MEMBER_GENDER: "number_board_member_gender",
  NUMBER_BOARD_MEMBER_REMUNERATED_GENDER: "number_board_member_remunerated_gender",
  RELATIONSHIP_STAKEHOLDERS_TABLE: "relationship_stakeholders_table",
  INDUSTRIAL_WASTE_TABLE: "industrial_waste_table",
  WATER_CONSUMPTION_TABLE: "water_consumption_table",
  OCCUPATIONAL_DISEASES_TABLE: "occupational_diseases_table",
  REFRIGERANT_GASES_CONSUMPTION_TABLE: "refrigerant_gases_consumption_table",
  ELECTRICITY_CONSUMPTION_TABLE: "electricity_consumption_table",
  ENERGY_CONSUMPTION_TABLE: "energy_consumption_table",
  POLLUTANT_EMISSIONS_TABLE: 'pollutant_emissions_table',
  DONATION_FOUNDATION_TABLE: "donation_foundation_table",
  RAW_MATERIAL_CONSUMPTION_TABLE: "raw_material_consumption_table",
  TAX_FISCAL_TABLE: "tax_fiscal_table",
  EMPLOYEE_TABLE: "employee_table",
  CONTRACT_TYPE_TABLE: "contract_type_table",
  DISMISSAL_TABLE: "dismissal_table",
  REMUNERATION_TABLE: "remuneration_table",
  WAGE_GAP_TABLE: "wage_gap_table",
  REMUNERATION_RELATION_TABLE: "remuneration_relation_table",
  WORK_ACCIDENT_TABLE: "work_accident_table",
  HOUR_TRAINING_PLAN_TABLE: "hour_training_plan_table",
  COLLECTIVE_AGREEMENT_TABLE: "collective_agreement_table",
  SUMMARY_GEI_TABLE: "summary_GEI_table",
  INPUT_HOUR: "input_hour",
  INPUT_NUMBER: "input_number",
  COUNTRY_NUMBER_TABLE: 'country_number_table',
  CURRENCY_INPUT_NUMBER: 'currency_input_number'
};

export const QuestionValueMode = {
  MULTIPLE: "multiple", //seleccion multiple
  SINGLE: "single", //seleccion unico valor,
};

export const QuestionNameMode = {
  LANGUAGE: "language",
  BUSINESS_GROUP: "business_group",
};

export const NotificationQuestionType = {
  INFO: "info",
  WARNING: "warning",
};

export const ComplementaryQuestionType = {
  NARRATIVE: "narrative",
  QUESTION: "question",
};

export const AssociatedEntityQuestion = {
  ORGANIZATION_COUNTRY: "organization_country",
  ORGANIZATION_COMPANY: "organization_company",
  STAKEHOLDER_INTERNAL: "stakeholder_internal",
  STAKEHOLDER_EXTERNAL: "stakeholder_external",
};

export const EinfStatus = {
  OPEN: "open",
  CLOSED: "closed",
};

export const ReportStatus = {
  OPEN: "open",
  CLOSED: "closed",
};


export const TemporalityType = {
  MONTHLY: "monthly",
  ANNUAL: "annual",
  QUARTERLY: "quarterly",
};

export const GenderType = {
  MALE: "male",
  FEMALE: "female",
};

export const MeasureUnit = {
  KG: "kg",
  TN: "Tn",
  M3: "m3",
  TnCO2: "Tn Co2 eq",
  KgCO2: "kg Co2 eq",
  GJ: "GJ",
  M: "m",
  L: "l",
  KWH: "kWh",
  MWH: "MWh",
};

export const Genders = ["male", "female"];

export const ContractTypes = ["indefinite_part_time", "permanent_full_time", "part_time_temporary", "full_time_temporary"];

export const ContractType = {
  INDEFINITE_PART_TIME: "indefinite_part_time",
  PERMANENT_FULL_TIME: "permanent_full_time",
  PART_TIME_TEMPORARY: "part_time_temporary",
  FULL_TIME_TEMPORARY: "full_time_temporary",
};


export const ServiceType = {
  'ESRS': 'esrs',
  'DM': 'double_materiality'
}

export const licenseTypes = {
  MASTER: "master",
  DATA: "data",
};


export const QuestionKeys = {
  CONTRACT_TYPES_TABLE: 'contractTypesTable',
  NUMBER_EMPLOYEES_TABLE: 'numberEmployeesTable',
  WATER_CONSUMPTION_TABLE: 'waterConsumptionTable',
  ELECTRICITY_CONSUMPTION_TABLE: 'electricityConsumptionTable'
}


export const SectionType = {
  MATERIALITY_ANALYSIS: 'materialityAnalysis',
  BUSINESS_MODEL: 'bussinesModel',
  RISK: 'risk',
  ENVIRONMENT: 'environment',
  SOCIAL: 'social',
  HUMAN_RIGHTS: 'humanRights',
  CORRUPTION: 'corruption',
  SUSTAINABLE_DEVELOPMENT: 'sustainableDevelopment',
  SUPPLIERS: 'suppliers',
  CLIENTS: 'clients',
  FISCAL: 'fiscal'
};



export const LayoutFile = {
  EXCEL_STANDARD: 'excel_standard',
}



export const UploadProcessStatus = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
  ERROR: 'error',
}


export const ImportDataType = {
  INDUSTRIAL_WASTE_TABLE: 'industrial_waste_table',
  WATER_CONSUMPTION_TABLE: 'water_consumption_table',
  OCCUPATIONAL_DISEASES_TABLE: 'occupational_diseases_table',
  REFRIGERANT_GASES_CONSUMPTION_TABLE: 'refrigerant_gases_consumption_table',
  ELECTRICITY_CONSUMPTION_TABLE: 'electricity_consumption_table',
  FIXED_COMBUSTION_CONSUMPTION_TABLE: 'fixed_combustion_consumption_table',
  MOBILE_COMBUSTION_CONSUMPTION_TABLE: 'mobile_combustion_consumption_table',
  RAW_MATERIAL_CONSUMPTION_TABLE: 'raw_material_consumption_table',
  EMPLOYEE_TABLE: 'employee_table',
  CONTRACT_TYPE_TABLE: 'contract_type_table',
  CONTRACT_AVERAGE_TABLE: 'contract_average_table',
  DISMISSAL_TABLE: 'dismissal_table',
  REMUNERATION_TABLE: 'remuneration_table',
  WORK_ACCIDENT_TABLE: 'work_accident_table',
  HOUR_TRAINING_PLAN_TABLE: 'hour_training_plan_table',
}

export const MaterialityType = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  NO: 'no'
}

export const TemplateStatus = {
  CREATED: 'created',
  PUBLISHED: 'published',
  INACTIVE: 'inactive',
  CLOSED: 'closed'
}

export const Currency = { //Estandarizar segun ISO 4217
  EUR: 'eur',
  KEUR: 'keur', //Miles de euros
  USD: 'usd',
  GBP: 'gbp'
}

export const AnswerStatus = {
  PENDING: 'pending',
  NOT_IRO: 'not_iro',
  WITH_IRO: 'with_iro'
}


